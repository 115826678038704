<template>
    <div class="left-dashboard fixed h-100 overflow-y-auto" style="background: #f2f6fc;">
        <div class="pa4" style="background-color: #e9edfc; cursor: pointer;">
            <a href="https://simplebks.com" target="_blank">
                <div class="pb3">
                    <img src="../assets/images/simple-logo-mobile.svg" alt="www.simplebks.com" />
                </div>
            </a>
        </div>

        <div class="overflow">
            <div>
                <router-link class="flex items-center ph4 pb3 pt3" active-class="active" :to="{ name: 'AdminDashboard' }">
                    <img :src="require('@/assets/images/Dashboard-active.svg')" style="width: 15px" alt="" />
                    <div class="pl3">Dashboard</div>
                </router-link>
                <router-link
                    class="flex items-center ph4 pv2x"
                    :class="{ active: $route.fullPath.startsWith('/account') }"
                    active-class="active"
                    :to="{ name: 'AdminAccount' }"
                >
                    <img :src="require('@/assets/images/Transactions.svg')" style="width: 15px" alt="" />
                    <div class="pl3">Account</div>
                </router-link>
                <router-link
                    class="flex items-center ph4 pv2x"
                    :class="{ active: $route.fullPath.startsWith('/activities') }"
                    active-class="active"
                    :to="{ name: 'AdminActivities' }"
                >
                    <img :src="require('@/assets/images/Transactions.svg')" style="width: 15px" alt="" />
                    <div class="pl3">Activities</div>
                </router-link>
                <router-link v-if="$store.state.Admin.adminUserData.userType === 'admin'"
                    class="flex items-center ph4 pv2x"
                    :class="{ active: $route.fullPath.startsWith('/admin/setting') }"
                    active-class="active"
                    :to="{ name: 'AdminSettingIndex' }"
                >
                    <img :src="require('@/assets/images/settings.svg')" style="width: 15px" alt="" />
                    <div class="pl3">Settings</div>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AdminSideBar',

    setup() {  

        return {}
    }
}
</script>

<style scoped>
.pv2 {
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
}
.pv2x {
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
}
.overflow {
    overflow-x: hidden !important;
    overflow-y: auto !important;
}

.footer {
    bottom: 8px;
    width: 90%;
    position: absolute;
    /* margin-left: 20px; */
    /* margin-right: 20px; */
    bottom: 0;
    width: 100%;
}

.no-wrap {
    white-space: nowrap;
}

.dropdown {
    position: relative;
    display: flex;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    /* min-width: 80%; */
    width: 100%;
    /* left: 14rem; */
    top: 3rem;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.432);
    z-index: 99999999999999999999 !important;
    color: #58595f;
    border-radius: 4px;
}

.dropdown:hover .dropdown-content {
    display: block;
    z-index: 99999999999999999999 !important;
}

.dropdown-content p {
    list-style-type: none !important;
    /* margin-bottom: 20px; */
    font-size: 14px;
    /* font-weight: bold; */
    /* padding: 16px; */
    padding: 8px 16px;
    z-index: 9999999999999999999999 !important;
    overflow: hidden;
}

.dropdown-content p:hover {
    background-color: #132c8c !important;
    padding: 0px;
    color: #ffffff;
    /* padding: 16px; */
    padding: 8px 16px;
    z-index: 999999999999999999999 !important;
}
</style>
