<template>
    <div class="relative">
        <AdminSideBar />
        <Alert />
        <div class="right-dashboard mb6" style="margin-left: auto">
            <div class="mobile-line" style="top: 0">
                <div class="flex justify-between w-90 center pv3">
                    <AdminMobileMenu />

                    <div class="searchContainerx"></div>

                    <div class="flex flex-start">
                        <div class="profile-dropdown relative" @focusout="handleShowMenu()">
                            <a class="db" href="#" @click.prevent="handleShowMenu(true)">
                                <img :src="require('@/assets/images/profile.svg')" alt="" />
                            </a>
                            <div
                                class="absolute"
                                style="
                                    width: 230px;
                                    background: white;
                                    box-shadow: 0px 12px 16px rgba(0, 0, 0, 0.08);
                                    border-radius: 8px;
                                    left: auto;
                                    right: 2px;
                                "
                                v-if="state.showMenu"
                            >
                                <!-- <router-link
                                    :to="{ name: 'AdminDashboard' }"
                                    class="flex items-center pv2 ph3 overflow-hidden menu-hover"
                                >
                                    <img :src="require('@/assets/images/user-profile.svg')" style="width: 20px" alt="" />
                                    <div class="font-w1 pl3">Account</div>
                                </router-link> -->
                                <router-link
                                    :to="{ name: 'AdminSettingIndex' }"
                                    class="flex items-center pv2 ph3 overflow-hidden menu-hover"
                                >
                                    <!-- <img src="../assets/images/settings-cog.svg" style="width: 20px" alt="" /> -->
                                    <img :src="require('@/assets/images/settings-cog.svg')" style="width: 20px" alt="" />
                                    <div class="font-w1 pl3">Settings</div>
                                </router-link>
                                <router-link
                                    to="#"
                                    @click.prevent="logOut()"
                                    class="flex items-center pv2 ph3 overflow-hidden menu-hover"
                                >
                                    <!-- <img src="../assets/images/logout.svg" style="width: 20px" alt="" /> -->
                                    <img :src="require('@/assets/images/logout.svg')" style="width: 20px" alt="" />
                                    <div class="font-w1 pl3">Log Out</div>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mini-spacing"></div>
            <slot name="child"></slot>
        </div>
    </div>
</template>

<script>
import { reactive } from 'vue'
import { useStore } from 'vuex'
import AdminSideBar from '@/components/AdminSideBar'
import AdminMobileMenu from '@/components/AdminMobileMenu'
import Alert from '@/components/Alert'
// import AlgoliaGlobalSearch from '@/components/AlgoliaGlobalSearch'

const bgBtn = require('@/assets/images/bg-btn.png')

export default {
    name: 'AppWrapper',
    components: { AdminSideBar, AdminSideBar, Alert/*, AlgoliaGlobalSearch*/, AdminMobileMenu },

    setup() {
        const store = useStore()

        const state = reactive({
            showMenu: false
        })

        const handleShowMenu = (payload = false) => {
            setTimeout(() => {
                state.showMenu = payload
            }, 300)
        }

        const logOut = () => {
            store.dispatch('Admin/logout')
        }

        return {
            state,
            handleShowMenu,
            logOut
        }
    }
}
</script>

<style scoped>
.menu-hover:hover {
    background-color: rgb(233, 237, 252);
    border-radius: 8px;
    /* transform: scale(1.1); */
    transition: all 0.3s ease-in-out;
}
</style>
