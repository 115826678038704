<template>
    <AdminWrapper>
        <template v-slot:child>
            <!--Greeting, stats, overview -->
            <template v-if="$store.state.Admin.loadingState"><Spinner /></template>

            <div class="w-90 center mt5 pt0-l pt4 f4 b">Hi {{ $store.state.Admin.adminUserData?.firstName }},</div>
            <div class="flex flex-wrap justify-between items-center w-90 center pv3">
                <div class="pb3 pb0-l">
                    <p>
                        Here is what is going on with
                        <span class="b" style="color: #132C8C; text-transform: capitalize;">{{ part.text || part || '-' }}</span>
                    </p>
                </div>
                <div class="flex makeFlex">
                    <input 
                        @change="onChangeDate()" 
                        v-model="startDate" 
                        class="mr2 makeInputTop dateInput" 
                        type="date" 
                        name="" 
                        id="" 
                    />
                    <input
                        @change="onChangeDate()"
                        v-model="endDate"
                        class="mr2 makeInputTop dateInput"
                        type="date"
                        name=""
                        id=""
                    />
                    <select
                        @change="ev => onChangeFixed(ev.target.value)"
                        class="makeInputTop dateInput"
                        type="date"
                        name=""
                        id=""
                    >
                        <option value="">All</option>
                        <option value="today">Show stats: Today</option>
                        <option value="last7days">Show stats: Last Week</option>
                        <option value="thisMonth">Show stats: This Month</option>
                        <option value="thisYear">Show stats: This Year</option>
                    </select>
                </div>
                <div class="scrollmenu">
                    <form action="" v-if="$store.state.Admin.adminUserData.userType === 'admin'">
                        <label class="b pr2" for="partners">Partners</label>
                        <select
                            @change="onChange(part.id || part)"
                            v-model="part"
                            class="b"
                            style="padding: 5px; color: #132C8C;"
                            id="UserSelect"
                        >
                            <option value="" disabled>Pick Partner</option>
                            <option value="all">All</option>
                            <option
                                v-for="partner in partners"
                                :key="partner._id"
                                :value="{ id: partner._id, text: partner.name }"
                                >{{ partner.name }}</option
                            >
                        </select>
                    </form>
                </div>
            </div>
            <!--Greeting, stats, overview -->

            <!-- Cards  -->
            <div class="w-90 center flex flex-wrap pt2">
                <!-- no of users-->
                <div class="pv4 pl3-l pl2 w-20-l w-50 box-border1 adj-text">
                    <div class="flex items-center">
                        <img :src="require('@/assets/images/users-svg.svg')" alt="" />
                        <p class="pl2 font-w1 fs13 makeFont600weight fs13 makeFont600weight">Number of Business</p>
                    </div>
                    <div class="pt3 fs13">
                        <div>{{ $store.state.Admin?.dashboardUsers.today || '0' }} today</div>
                    </div>
                    <div style="color: #132C8C;">
                        <h3 class="mb0 fs13">{{ $store.state.Admin?.dashboardUsers.count }}</h3>
                    </div>
                </div>
                <!-- no of users -->

                <!-- active users -->
                <div class="pv4 pl3-l pl2 w-20-l w-50 box-border1 adj-text">
                    <div class="flex items-center">
                        <img :src="require('@/assets/images/revenue.png')" alt="" />
                        <p class="pl2 font-w1 fs13 makeFont600weight fs13 makeFont600weight">Active Business</p>
                    </div>
                    <div class="pt3 fs13">
                        <div>{{ $store.state.Admin?.dashboardActiveUsers.today || '0' }} today</div>
                    </div>
                    <div style="color: #132C8C;">
                        <h3 class="mb0">{{ $store.state.Admin?.dashboardActiveUsers.count }}</h3>
                    </div>
                </div>
                <!-- active users -->

                <!-- transaction -->
                <div class="pv4 pl3-l pl2 w-20-l w-50 box-border1 adj-text">
                    <div class="flex items-center">
                        <img :src="require('@/assets/images/trans-admin.svg')" alt="" />
                        <p class="pl2 font-w1 fs13 makeFont600weight">Transaction</p>
                    </div>
                    <div class="flex justify-between">
                        <div>
                            <div class="pt3">
                                <div>
                                    Value
                                </div>
                            </div>
                            <div style="color: #132C8C;">
                                <h3 class="mb0">
                                    {{ formatAmount($store.state.Admin?.dashboardTransaction.value) }}
                                </h3>
                            </div>
                        </div>
                        <div class="pr3-l pr2 pl1">
                            <div class="pt3">
                                <div>
                                    Count
                                </div>
                            </div>
                            <div style="color: #132C8C;">
                                <h3 class="mb0">{{ $store.state.Admin?.dashboardTransaction.count }}</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Transaction-->

                <!-- invoice-->
                <div class="pv4 pl3-l pl2 w-20-l w-50 box-border1 adj-text">
                    <div class="flex items-center">
                        <img :src="require('@/assets/images/invoice-admin.svg')" alt="" />
                        <p class="pl2 font-w1 fs13 makeFont600weight">Invoice</p>
                    </div>
                    <div class="flex justify-between">
                        <div>
                            <div class="pt3">
                                <div>
                                    Value
                                </div>
                            </div>
                            <div style="color: #132C8C;">
                                <h3 class="mb0">
                                    {{ formatAmount($store.state.Admin?.dashboardInvoice.value) }}
                                </h3>
                            </div>
                        </div>
                        <div class="pr3-l pr2 pl1">
                            <div class="pt3">
                                <div>
                                    Count
                                </div>
                            </div>
                            <div style="color: #132C8C;">
                                <h3 class="mb0">{{ $store.state.Admin?.dashboardInvoice.count }}</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- invoice -->

                <!-- product-->
                <div class="pv4 pl3-l pl2 w-20-l w-50 box-border1 adj-text">
                    <div class="flex items-center">
                        <img :src="require('@/assets/images/product-admin.svg')" alt="" />
                        <p class="pl2 font-w1 fs13 makeFont600weight">Product</p>
                    </div>
                    <div class="pt3">
                        <div>{{ $store.state.Admin?.dashboardProducts.today || '0' }} today</div>
                    </div>
                    <div style="color: #132C8C;">
                        <h3 class="mb0">{{ $store.state.Admin?.dashboardProducts.count }}</h3>
                    </div>
                </div>
                <!-- producct -->
                <!-- Entities-->
                <div class="pv4 pl3-l pl2 w-20-l w-50 box-border1 adj-text mt4-l">
                    <div class="flex items-center">
                        <img :src="require('@/assets/images/entities-admin.png')" alt="" />
                        <p class="pl2 font-w1 fs13 makeFont600weight">Entities</p>
                    </div>
                    <div class="pt3">
                        <div>{{ $store.state.Admin?.dashboardEntities.today || '0' }} today</div>
                    </div>
                    <div style="color: #132C8C;">
                        <h3 class="mb0">{{ $store.state.Admin?.dashboardEntities.count }}</h3>
                    </div>
                </div>
                <!-- Entities -->

                <!-- Services -->
                <div class="pv4 pl3-l pl2 w-20-l w-50 box-border1 adj-text mt4-l">
                    <div class="flex items-center">
                        <img :src="require('@/assets/images/services-admin.svg')" alt="" />
                        <p class="pl2 font-w1 fs13 makeFont600weight">Services</p>
                    </div>
                    <div class="pt3">
                        <div>{{ $store.state.Admin?.dashboardServices.today || '0' }} today</div>
                    </div>
                    <div style="color: #132C8C;">
                        <h3 class="mb0">{{ $store.state.Admin?.dashboardServices.count }}</h3>
                    </div>
                </div>
                <!-- Services -->

                <!-- paying customers -->
                <div class="pv4 pl3-l pl2 w-20-l w-50 box-border1 adj-text mt4-l">
                    <div class="flex items-center">
                        <img :src="require('@/assets/images/paying-admin.svg')" alt="" />
                        <p class="pl2 font-w1 fs13 makeFont600weight">Pay Customer</p>
                    </div>
                    <div class="flex items-center pt3">
                        <div class="flex items-center">
                            <img src="imgs/increase.svg" alt="" />
                            <p class="pl2">{{ $store.state.Admin?.dashboardPayingCustomers.count }}</p>
                        </div>
                        <div class="pl2">
                            +{{ $store.state.Admin?.dashboardPayingCustomers.today || 0 }} today
                        </div>
                    </div>
                    <div style="color: #132C8C;">
                        <h3 class="mb0">{{ formatAmount($store.state.Admin?.dashboardPayingCustomers.value) }}</h3>
                    </div>
                </div>
                <!-- paying customers-->

                <!-- users by plan-->
                <div class="pv4 pl3-l pl2 w-20-l w-50 box-border1 adj-text mt4-l">
                    <div class="flex items-center">
                        <img :src="require('@/assets/images/users-plan-admin.svg')" alt="" />
                        <p class="pl2 font-w1 fs13 makeFont600weight">Users By Plan</p>
                    </div>
                    <div class="" style="color: #132C8C;">
                        <h4 class="mb0">Micro ({{ $store.state.Admin?.dashboardPlans.micro }})</h4>
                    </div>
                    <div class="" style="color: #132C8C;">
                        <h4 class="mb0">Small ({{ $store.state.Admin?.dashboardPlans.small }})</h4>
                    </div>
                    <div class="" style="color: #132C8C;">
                        <h4 class="mb0">Medium ({{ $store.state.Admin?.dashboardPlans.medium }})</h4>
                    </div>
                </div>
                <!-- users by plan -->

                <!-- login-->
                <div class="pv4 pl3-l pl2 w-20-l w-50 box-border1 adj-text mt4-l">
                    <div class="flex items-center">
                        <img :src="require('@/assets/images/Login-admin.svg')" alt="" />
                        <p class="pl2 font-w1 fs13 makeFont600weight">Login</p>
                    </div>
                    <div class="pt3">
                        <div>
                            Today
                        </div>
                    </div>
                    <div style="color: #132C8C;">
                        <h3 class="mb0">{{ $store.state.Admin?.dashboardLogin }}</h3>
                    </div>
                </div>
                <!-- login -->
            </div>

            <!-- Cards -->

            <!--  graph -->
            <!-- <div class="pv4">
                <div class="w-90 center">
                    <div class="flex flex-wrap justify-between pt3">
                        <div class="box-border w-30-l w-100 mb0-l mb4">
                            <div class="flex pa3 items-center justify-between box-border-bottom">
                                <div>
                                    <div>Activity</div>
                                    <div class="b pt1">Users</div>
                                </div>
                                <select name="" id="" style="padding: 5px;">
                                    <option value="">Monthly</option>
                                    <option value="">Last 30 days</option>
                                    <option value="">Today</option>
                                    <option value="">Weekly</option>
                                </select>
                            </div>
                            <div class="pa3 justify-between">
                                <div class=" w-100 pt2 pt0-l">
                                    <img src="imgs/admin-graph1.svg" style="width: 100%;" alt="" />
                                </div>
                            </div>
                        </div>
                   
                        <div class="box-border w-30-l w-100 mb0-l mb4">
                            <div class="flex pa3 items-center justify-between box-border-bottom">
                                <div>
                                    <div>Activity</div>
                                    <div class="b pt1">Active Users</div>
                                </div>
                                <select name="" id="" style="padding: 5px;">
                                    <option value="">Monthly</option>
                                    <option value="">Last 30 days</option>
                                </select>
                            </div>
                            <div class="pa3 justify-between">
                                <div class=" w-100 pt2 pt0-l">
                                    <img src="imgs/admin-graph1.svg" style="width: 100%;" alt="" />
                                </div>
                            </div>
                        </div>

                        <div class="box-border w-33-l w-100 mb0-l mb4">
                            <div class="flex pa3 items-center justify-between box-border-bottom">
                                <div>
                                    <div>Activity</div>
                                    <div class="b pt1">Transaction</div>
                                </div>
                                <select name="" id="" style="padding: 5px;">
                                    <option value="">Monthly</option>
                                    <option value="">Last 30 days</option>
                                </select>
                            </div>
                            <div class="flex pa3 items-center justify-between ">
                                <div>
                                    <div class="b pt1">N2,574,000</div>
                                </div>
                                <div>
                                    <div>Count <span class="b">300</span></div>
                                </div>
                            </div>
                            <div class="pa3 justify-between">
                                <div class=" w-100 pt2 pt0-l">
                                    <img src="imgs/admin-trans-graph.svg" style="width: 100%;" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->
        </template>
    </AdminWrapper>
</template>

<script>
import { computed, onMounted, ref } from '@vue/runtime-core'
import AdminWrapper from '../../../layout/AdminWrapper.vue'
import { useStore } from 'vuex'
import Spinner from '@/components/Spinner'
import { formatAmount, formatDateFilter } from '@/utils/lib'
import moment from 'moment-timezone'
export default {
    components: {
        AdminWrapper,
        Spinner
    },
    setup() {
        const partners = computed(() => store.state.Admin.partners)
        const store = useStore()
        const part = ref('')
        const getId = ref('')
        const getNameOnload = ref('')
        const isAdminGetAll = ref(false)
        const startDate = ref(formatDateFilter(new Date(new Date().setFullYear(new Date().getFullYear() - 23))))
        const endDate = ref(formatDateFilter(new Date()))
        const today = new Date()
        const year = today.getFullYear()
        const month = today.getMonth()
        const day = today.getDate()

        const onChangeDate = e => {
            if (startDate.value > endDate.value) {
                return store.dispatch('Alert/setAlert', {
                    message: 'Start date cannot be greater than end date',
                    status: false
                })
            }

            startDate.value = formatDateFilter(startDate.value)
            endDate.value = formatDateFilter(endDate.value)

            const query = `startDate=${startDate.value}&endDate=${endDate.value}`

            if ((part.value = 'all')) {
                store.dispatch('Admin/getDahsboardAdminUserData', query)
                store.dispatch('Admin/getDahsboardAdminTransactionData', query)
                store.dispatch('Admin/getDahsboardAdminInvoiceData', query)
                store.dispatch('Admin/getDahsboardAdminPayingCustomerData', query)
            } else {
                store.dispatch('Admin/getDahsboardActiveUserData', { id: getId.value, query: query })
                store.dispatch('Admin/getDahsboardTransactionData', { id: getId.value, query: query })
                store.dispatch('Admin/getDahsboardInvoiceData', { id: getId.value, query: query })
                store.dispatch('Admin/getDahsboardAdminPayingCustomerData', query)
            }
        }

        const onChange = e => {
            startDate.value = formatDateFilter(new Date(new Date().setFullYear(new Date().getFullYear() - 23)))
            endDate.value = formatDateFilter(new Date())

            const query = `startDate=${startDate.value}&endDate=${endDate.value}`

            getId.value = e

            if (e === 'all') {
                store.dispatch('Admin/getDahsboardAllBusinessData')
                store.dispatch('Admin/getDahsboardAdminUserData', query)
                store.dispatch('Admin/getDahsboardAdminActiveUserData')
                store.dispatch('Admin/getDahsboardAdminTransactionData', query)
                store.dispatch('Admin/getDahsboardAdminInvoiceData', query)
                store.dispatch('Admin/getDahsboardAdminProductData')
                store.dispatch('Admin/getDahsboardAdminEntityData')
                store.dispatch('Admin/getDahsboardAdminServiceData')
                store.dispatch('Admin/getDahsboardAdminPlansData')
                store.dispatch('Admin/getDahsboardAdminLoginData')
            } else {
                store.dispatch('Admin/getDahsboardUserData', { id: e, query: query })
                store.dispatch('Admin/getDahsboardActiveUserData', e)
                store.dispatch('Admin/getDahsboardTransactionData', { id: e, query: query })
                store.dispatch('Admin/getDahsboardInvoiceData', { id: e, query: query })
                store.dispatch('Admin/getDahsboardProductData', e)
                store.dispatch('Admin/getDahsboardEntityData', e)
                store.dispatch('Admin/getDahsboardServiceData', e)
                store.dispatch('Admin/getDahsboardPlansData', e)
                store.dispatch('Admin/getDahsboardLoginData', e)
            }
        }

        // check if this logged in usertype is an admin, then get all business data else
        // get partner businesses only
        const checkifAll = () => {
            startDate.value = formatDateFilter(new Date(new Date().setFullYear(new Date().getFullYear() - 23)))
            endDate.value = formatDateFilter(new Date())

            const query = `startDate=${startDate.value}&endDate=${endDate.value}`

            if (store.state.Admin.adminUserData.userType === 'admin') {
                store.dispatch('Admin/getDahsboardAllBusinessData')
                store.dispatch('Admin/getDahsboardAdminUserData', query)
                store.dispatch('Admin/getDahsboardAdminActiveUserData')
                store.dispatch('Admin/getDahsboardAdminTransactionData', query)
                store.dispatch('Admin/getDahsboardAdminInvoiceData', query)
                store.dispatch('Admin/getDahsboardAdminProductData')
                store.dispatch('Admin/getDahsboardAdminEntityData')
                store.dispatch('Admin/getDahsboardAdminServiceData')
                store.dispatch('Admin/getDahsboardAdminPayingCustomerData', query)
                store.dispatch('Admin/getDahsboardAdminPlansData')
                store.dispatch('Admin/getDahsboardAdminLoginData')
                part.value = 'all'
            } else {
                const partner = store.state.Admin.adminUserData.partner;
                part.value = { id: partner?._id, text: partner?.name };
                getId.value = partner?._id
                store.dispatch('Admin/getDahsboardUserData', { id: getId.value, query: query })
                store.dispatch('Admin/getDahsboardActiveUserData', getId.value)
                store.dispatch('Admin/getDahsboardTransactionData', { id: getId.value, query: query })
                store.dispatch('Admin/getDahsboardInvoiceData', { id: getId.value, query: query })
                store.dispatch('Admin/getDahsboardProductData', getId.value)
                store.dispatch('Admin/getDahsboardEntityData', getId.value)
                store.dispatch('Admin/getDahsboardServiceData', getId.value)
                // store.dispatch('Admin/getDahsboardPayingCustomerData', getId.value)
                store.dispatch('Admin/getDahsboardPlansData', getId.value)
                store.dispatch('Admin/getDahsboardLoginData', getId.value)
            }
        }

        const onChangeFixed = async val => {
            if (val === 'thisMonth') {
                startDate.value = moment(new Date(year, month, 1)).format('YYYY-MM-DD')
                endDate.value = moment(new Date()).format('YYYY-MM-DD')

                const query = `startDate=${startDate.value}&endDate=${endDate.value}`

                if (part.value === 'all') {
                    store.dispatch('Admin/getDahsboardAdminUserData', query)
                    store.dispatch('Admin/getDahsboardAdminTransactionData', query)
                    store.dispatch('Admin/getDahsboardAdminInvoiceData', query)
                } else {
                    store.dispatch('Admin/getDahsboardUserData', { id: getId.value, query: query })
                    store.dispatch('Admin/getDahsboardTransactionData', { id: getId.value, query: query })
                    store.dispatch('Admin/getDahsboardInvoiceData', { id: getId.value, query: query })
                }
            } else if (val === 'today') {
                startDate.value = new Date().toISOString().slice(0, 10)
                endDate.value = new Date().toISOString().slice(0, 10)

                const query = `startDate=${startDate.value}&endDate=${endDate.value}`

                if (part.value === 'all') {
                    store.dispatch('Admin/getDahsboardAdminUserData', query)
                    store.dispatch('Admin/getDahsboardAdminTransactionData', query)
                    store.dispatch('Admin/getDahsboardAdminInvoiceData', query)
                } else {
                    store.dispatch('Admin/getDahsboardUserData', { id: getId.value, query: query })
                    store.dispatch('Admin/getDahsboardTransactionData', { id: getId.value, query: query })
                    store.dispatch('Admin/getDahsboardInvoiceData', { id: getId.value, query: query })
                }
            } else if (val === 'thisYear') {
                startDate.value = moment(new Date(year, 0, 1)).format('YYYY-MM-DD')
                endDate.value = moment(new Date()).format('YYYY-MM-DD')

                const query = `startDate=${startDate.value}&endDate=${endDate.value}`

                if (part.value === 'all') {
                    store.dispatch('Admin/getDahsboardAdminUserData', query)
                    store.dispatch('Admin/getDahsboardAdminTransactionData', query)
                    store.dispatch('Admin/getDahsboardAdminInvoiceData', query)
                } else {
                    store.dispatch('Admin/getDahsboardUserData', { id: getId.value, query: query })
                    store.dispatch('Admin/getDahsboardTransactionData', { id: getId.value, query: query })
                    store.dispatch('Admin/getDahsboardInvoiceData', { id: getId.value, query: query })
                }
            } else if (val === 'thisWeek') {
                const last7days = new Date(year, month, day - 7)
                startDate.value = moment(last7days).format('YYYY-MM-DD')
                endDate.value = moment(new Date()).format('YYYY-MM-DD')

                const query = `startDate=${startDate.value}&endDate=${endDate.value}`

                if (part.value === 'all') {
                    store.dispatch('Admin/getDahsboardAdminUserData', query)
                    store.dispatch('Admin/getDahsboardAdminTransactionData', query)
                    store.dispatch('Admin/getDahsboardAdminInvoiceData', query)
                } else {
                    store.dispatch('Admin/getDahsboardUserData', { id: getId.value, query: query })
                    store.dispatch('Admin/getDahsboardTransactionData', { id: getId.value, query: query })
                    store.dispatch('Admin/getDahsboardInvoiceData', { id: getId.value, query: query })
                }
            } else if (val === 'lastMonth') {
                const lastMonth = new Date(year, month - 1, 1)
                const lastMonthEnd = new Date(year, month, 0)
                startDate.value = moment(lastMonth).format('YYYY-MM-DD')
                endDate.value = moment(lastMonthEnd).format('YYYY-MM-DD')

                const query = `startDate=${startDate.value}&endDate=${endDate.value}`

                if (part.value === 'all') {
                    store.dispatch('Admin/getDahsboardAdminUserData', query)
                    store.dispatch('Admin/getDahsboardAdminTransactionData', query)
                    store.dispatch('Admin/getDahsboardAdminInvoiceData', query)
                } else {
                    store.dispatch('Admin/getDahsboardUserData', { id: getId.value, query: query })
                    store.dispatch('Admin/getDahsboardTransactionData', { id: getId.value, query: query })
                    store.dispatch('Admin/getDahsboardInvoiceData', { id: getId.value, query: query })
                }
            } else if (val === 'all') {
                startDate.value = formatDateFilter(new Date(new Date().setFullYear(new Date().getFullYear() - 23)))
                endDate.value = formatDateFilter(new Date())

                const query = `startDate=${startDate.value}&endDate=${endDate.value}`

                if (part.value === 'all') {
                    store.dispatch('Admin/getDahsboardAdminUserData', query)
                    store.dispatch('Admin/getDahsboardAdminTransactionData', query)
                    store.dispatch('Admin/getDahsboardAdminInvoiceData', query)
                } else {
                    store.dispatch('Admin/getDahsboardUserData', { id: getId.value, query: query })
                    store.dispatch('Admin/getDahsboardTransactionData', { id: getId.value, query: query })
                    store.dispatch('Admin/getDahsboardInvoiceData', { id: getId.value, query: query })
                }
            }

        }

        onMounted(async () => {
            await store.dispatch('Admin/getPartners');
            checkifAll();
        })

        return {
            partners,
            onChange,
            part,
            getNameOnload,
            checkifAll,
            isAdminGetAll,
            formatAmount,
            startDate,
            endDate,
            onChangeDate,
            formatDateFilter,
            getId,
            onChangeFixed
        }
    }
}
</script>

<style scoped>
.makeFont600weight {
    font-weight: 600;
}

.fs13 {
    font-size: 13.5px;
}

.dateInput {
    border: 1px solid #e3e8ee;
    box-shadow: 0px 1px 3px rgb(0 0 0 / 12%);
    border-radius: 4px;
}

@media screen and (max-width: 768px) {
    .makeFlex {
        flex-direction: row;
    }
    .makeInputTop {
        margin-top: 3px;
    }
}
</style>
