<template>
    <!-- mobile menu -->
    <div class="relative mobile">
        <div class="mobilenav" style="font-size: 25px; cursor: pointer; color: #84818a" @click.prevent="openNav">&#9776;</div>

        <div
            ref="mySidenav"
            id="mySidenav"
            class="sidenav"
            style="background: #f2f6fc; height: 100vh; position: fixed; padding-bottom: 2rem; z-index: 99999"
        >
            <span style="cursor:pointer" class="closebtn" @click.prevent="closeNav">&times;</span>
            <div class="pb5">
                <router-link class="flex items-center ph4 pb3 pt3" active-class="active" :to="{ name: 'AdminDashboard' }">
                    <img :src="require('@/assets/images/Dashboard-active.svg')" style="width: 15px" alt="" />
                    <div class="pl3">Dashboard</div>
                </router-link>
                <router-link
                    class="flex items-center ph4 pb3 pt1"
                    :class="{ active: $route.fullPath.startsWith('/account') }"
                    active-class="active"
                    :to="{ name: 'AdminAccount' }"
                >
                    <img :src="require('@/assets/images/Transactions.svg')" style="width: 15px" alt="" />
                    <div class="pl3">Account</div>
                </router-link>
                <router-link
                    class="flex items-center ph4 pb3 pt1"
                    :class="{ active: $route.fullPath.startsWith('/activities') }"
                    active-class="active"
                    :to="{ name: 'AdminActivities' }"
                >
                    <img :src="require('@/assets/images/Transactions.svg')" style="width: 15px" alt="" />
                    <div class="pl3">Activities</div>
                </router-link>
                <router-link
                    class="flex items-center ph4 pb3 pt1"
                    :class="{ active: $route.fullPath.startsWith('/transactions') }"
                    active-class="active"
                    :to="{ name: 'AdminSettingIndex' }"
                >
                    <img :src="require('@/assets/images/Transactions.svg')" style="width: 15px" alt="" />
                    <div class="pl3">Settings</div>
                </router-link>
            </div>
        </div>
    </div>
    <!-- mobile menu -->
</template>

<script>
import { computed, onMounted, ref, watch } from 'vue'
import { useStore } from 'vuex'
import bgBtn from '@/assets/images/bg-btn.png'

export default {
    name: 'MobileMenu',
    setup() {
        const store = useStore()
        const userData = computed(() => store?.state?.Auth?.userData)
        const orgInitials = computed(() => store?.state?.Auth?.orgInitials)
        const role = computed(() => store?.state?.Auth?.role)
        const rolePermissions = computed(() => store?.state?.Auth?.rolePermissions)
        const getStarted = computed(() => store?.state?.Settings?.getStarted)

        const mySidenav = ref(null)
        const mobileMenuRef = ref(null)
        const menuFooter = ref(null)

        function openNav() {
            mySidenav.value.style.width = '300px'
            mobileMenuRef.value.className = 'footer-mobile'
        }

        function closeNav() {
            mySidenav.value.style.width = '0'
            if (mobileMenuRef.value.classList.contains('footer-mobile')) {
                mobileMenuRef.value.classList.remove('footer-mobile')
            }
        }

        return {
            role,
            rolePermissions,
            getStarted,
            bgBtn,
            userData,
            openNav,
            closeNav,
            orgInitials,
            mySidenav,
            menuFooter,
            mobileMenuRef
        }
    }
}
</script>

<style scoped>
.pv2 {
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
}
.footer-mobile {
    /*position: absolute;*/
    /* bottom: -101px; */
    bottom: 0;
    /*width: 100%;*/
    position: fixed;
    width: 300px;
    animation: footerUserOrgAnim 1s forwards;
}

@keyframes footerUserOrgAnim {
    0% {
        bottom: -100px;
    }

    100% {
        bottom: 0;
    }
}
</style>
